<template>
  <div>
    <HeaderBasic>
      <h2 class="page-title">Property for Sale</h2>
      <router-link to="/" :exact="true">Home</router-link>
      <span> / </span>
      <span class="current">Property for Sale</span>
    </HeaderBasic>
    <PropertyFinder
      title_text="Latest properties For Sale"
      :offer_type="2"
      baseUrl="/PropertyFinder/"
    ></PropertyFinder>
  </div>
</template>
<script>
export default {
  components: {
    HeaderBasic: () => import("./HeaderBasic"),
    PropertyFinder: () => import("./PropertyFinder")
  },
  data() {
    return {};
  },
  mounted() {
    //this.$scrollTo("#content");
  }
};
</script>
